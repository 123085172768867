/**
 * Removes top margin from first occuring phrasing elements
 */
.xs-page-content tbody > tr:first-child > td:first-child > div:first-child > div:first-child > *:first-child,
.xs-page-content tbody > tr:first-child > td:first-child > div:first-child > *:first-child {
    margin-top: 0;
}

/**
 * Page content spacing
 */
.xs-page-content {
    padding-top: 20px;
}

/**
 * Table display issue
 */
.xs-main-content .xs-dynamic-content-manager .xs-page-content:first-child:last-child {
    display: table;
    margin-top: 20px;
    width: 100%;
}