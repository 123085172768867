/**
 * Header styles
 */
header {
    background-color: #fff;
    max-width: 768px;
    overflow: hidden;
    position: relative;

    nav.navbar {
        min-height: 0;
    }

    .header-image {
        height: 135px;
        overflow: hidden;
        position: relative;

        * {
            white-space: nowrap;
            z-index: 20;
        }

        &:after,
        &:before {
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }

        &:before {
            background: url(/graphics/Basic/home.png) left center no-repeat;
        }

        &:after {
            right: auto;
            width: 120px;
            z-index: 5;
        }
    }

    .company-logo {
        position: absolute;
        display: none;
       
        img {
            height: auto;
            width: auto;
        }
    }

    .company-name {
        line-height: 1;
        position: absolute;

        text-decoration: none;
        
        &:hover {
            text-decoration: none;
        }
    }

    .company-motto {
        line-height: 1;
        position: absolute;
    }
}
