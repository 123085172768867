/**
 * Main content area styles
 */
main {
    > [class*=col-] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }

    .container {
        padding: 0 30px 0 0;
    }
}