/**
 * Footer styles
 */
footer {
    font-size: 8pt !important;

    a {
        color: #0000EE;
        text-decoration: underline;

        &:hover {
            color: #0000EE;
        }

        &:visited {
            color: #551AAB;
        }
    }
}