/**
 * Primary navigation
 */
#xs-navbar-collapse {
    border-radius: 0;
    display: block;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    .xs-primary-navigation {
        width: 100%;

        li {
            float: left;
            min-height: 26px;
            overflow: hidden;
            text-align: center;
            width: ((100 / 12) * 2) + %;

            &.spacer {
                display: block;
                height: 26px;
                width: ((100 / 12) * 2) + %;
            }

            & ~ li {
                border-left: 1px solid #FFF;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    a:hover {
        background: none;
    }
}

.navbar {
    min-height: 30px;
}
.navbar-nav {
    > li > a {
        padding: 4px 0 2px;
    }

    a {
        overflow: hidden;
    }
}